/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_scpdexWDf",
    "aws_user_pools_web_client_id": "3ce14gpgt5mres6s0hcom90i8a",
    "oauth": {}
};


export default awsmobile;
